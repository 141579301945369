.app {
  max-width: 1200px;
  margin: 0 auto;
}

.job-list {
  margin-top: 20px;
}

.job-item {
  border: 1px solid #ccc;
  padding: 10px;
  margin-bottom: 10px;
  cursor: pointer;
}

.job-detail {
  border: 1px solid #ccc;
  padding: 20px;
  margin-top: 20px;
  white-space: pre-wrap;
}

.pagination {
  margin-top: 20px;
}

.pagination button {
  margin-right: 5px;
  cursor: pointer;
}

.expired-job {
  color: #aaa; /* Grey text for expired jobs */
}

.button {
  display: inline-block;
  padding: 10px 20px; /* Adjust padding to desired size */
  background-color: #007bff; /* Set background color */
  color: #fff; /* Set text color */
  text-decoration: none; /* Remove underline */
  border-radius: 5px; /* Add rounded corners */
  border: 1px solid transparent; /* Add border */
  cursor: pointer; /* Change cursor on hover */
  transition: background-color 0.3s, color 0.3s, border-color 0.3s; /* Add transition effect */
}

.button:hover {
  background-color: #0056b3; /* Change background color on hover */
  border-color: #0056b3; /* Change border color on hover */
}

.right {
  float: right;
  clear: right;
}