/* components/JobList.css */

.job-list-container {
  display: flex;
}

.job-list {
  flex: 1;
  padding-right: 20px; /* Adjust spacing between job list and description */
  padding-left: 0;
}

.job-list * {
  padding: 0;
  margin: 0;
}

.job-list .job-employer {
  font-size: 0.8em;
  font-weight: bold;
}

.job-list .job-title {
  font-size: 1em;
  margin: 3px 0 10px 0;
}

.job-item {
  cursor: pointer;
  padding: 10px;
  list-style: none;
}

.job-item.selected {
  background-color: #eef;
}

.selected-job {
  background-color: lightblue;
}

.job-description {
  flex: 1;
}
